<template>
  <div>
    <HeaderComponent />

    <div class="paginas">
      <router-view />
    </div>

    <FooterComponent />
    <MsgCookies v-if="warningCookies" @handle-click="handleCookies" />
    <MsgPromoEncerrada v-if="msgEncerra" @handle-click="handleMsg" />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import FooterComponent from "@/components/Footer.vue";
import HeaderComponent from "@/components/Header.vue";
import MsgCookies from "@/components/MsgCookies.vue";
import MsgPromoEncerrada from "@/components/MsgPromoEncerrada.vue";
export default {
  name: "LayoutClient",
  components: { HeaderComponent, FooterComponent, MsgCookies, MsgPromoEncerrada },
  data() {
    return { warningCookies: true, msgEncerra: false };
  },

  mounted() {
    document.body.classList.add("overflow-hidden");
  },
  beforeMount() {
    if (Cookies.get("acceptCookies")) {
      this.warningCookies = false;
      this.msgEncerra = true
      setTimeout(() => {
        document.body.classList.remove("overflow-hidden");
      });
    }
  },
  methods: {
    handleMsg() {
      this.msgEncerra = false;
      setTimeout(() => {
        document.body.classList.remove("overflow-hidden");
      });
    },
    handleCookies() {
      Cookies.set("acceptCookies", true, { expires: 3 });
      this.warningCookies = false;
      this.msgEncerra = true
      setTimeout(() => {
        document.body.classList.remove("overflow-hidden");
      });
    },
  },
};
</script>

<style scoped>
.paginas {
  padding-top: 40px;
}
</style>
