import { defineStore } from "pinia";
import { http } from "@/services/api";

export const storeGanhadores = defineStore("ganhadores", {
  state: () => ({
    listaGanhadores: '',

  }),
  actions: {
    ActiongetGanhadores() {
      if (!this.listaGanhadores) {
        http.get('ganhadores').then(res => {
          if (res.data.success) {
            this.listaGanhadores = res.data.texto[0].text
          }
        })
      }
    }
  },
});
