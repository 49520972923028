import { defineStore } from 'pinia'
import { http } from '@/services/api';

export const storeCadastro = defineStore('cadastro', {
    state: () =>
    ({
        loading: false,
        form: {
            cpf: '',
            name: "",
            birthday: "",
            phone: "",
            cep: "",
            uf: "",
            password: '',
            acceptUseData: false,
            acceptCupom: false,
            acceptRules: false,
        },
        checkRecaptcha: false,
    }),
    actions: {
        ActionCreateUsuario() {
            this.form.cpf = this.form.cpf.replace(/\./g, "").replace(/-/g, "").replace(/\//g, "");
            const url = this.form.cpf.length === 11 ? 'register' : 'registerPj'
            if (this.form.cpf.length > 11) {
                this.form.cnpj = this.form.cpf
                delete this.form.cpf
            }
            return http.post(url, this.form)
        }
    }
})
