<script setup>
import { onMounted, ref } from "vue";
import { storeCupons } from "../../store/cupons";
import { auth } from "../../store/auth";

import CadastroCupom from "../../components/CadastroCupom.vue";
import ListagemComponent from "../../components/ListagemComponent.vue";
import Section1 from "../../components/Section1.vue";
import router from "../../router";

const cuponsStore = storeCupons();
const authStore = auth();
const isListagem = ref(true);

async function submitCupom(payload) {
  try {
    cuponsStore.loading = true;
    payload.produtos = payload.produtos.map((x) => {
      return { id: x.name.value, qtd: x.qtd };
    });
    payload.cnpjLoja = payload.cnpjLoja
      .replace(/\./g, "")
      .replace(/-/g, "")
      .replace(/\//g, "");
    const res = await cuponsStore.ActionPostCupom(payload);
    window.__Swal
      .fire({
        icon: res.data.success ? "success" : "warning",
        title: res.data.success ? "Sucesso" : "Atenção",
        text: res.data.message,
      })
      .then(() => {
        cuponsStore.ActionGetCupons();
        isListagem.value = true;
      });
  } catch (error) {
    window.__Swal.fire({
      icon: "error",
      title: "Erro",
      text: "Erro ao salvar dados.",
    });
  } finally {
    cuponsStore.loading = false;
  }
}

function handleLogout() {
  authStore.ActionSingnout();
  router.push({ name: "Home" });
}

// function handleCadastro() { 
//    window.__Swal.fire({
//       icon: "warning",
//       title: "Atenção!",
//       text: "Promoção encerrada, acompanhe os ganhadores!",
//        }).then(() => {
//         router.push({ name: "Ganhadores" });
//     })
//   // isListagem = false
//  }

onMounted(() => {
  cuponsStore.ActionGetCupons();
});
</script>

<template>
  <Section1></Section1>
  <div class="container">
    <div class="row my-4 justify-content-center">
      <button
        class="btn-default col-md-3 mx-1 mb-4"
        :class="{ active: isListagem }"
        @click.prevent="isListagem = true"
      >
        MEUS CUPONS
      </button>
      <!-- <button class="btn-default col-md-3 mx-1 mb-4" :class="{ active: !isListagem }">MEUS DADOS</button> -->
      <button
        class="btn-default logout col-md-3 mx-1 mb-4"
        @click.prevent="handleLogout"
      >
        SAIR
      </button>
    </div>

    <div v-if="isListagem" class="mb-5">
      <ListagemComponent
        title="PRÊMIOS R$500,00 TODOS OS DIAS"
        :columns="cuponsStore.columns"
        :rows="cuponsStore.listCupons"
      />

      <!-- <div class="d-flex justify-content-center py-4">
        <button
          class="btn-default novo-cupom mb-4"
          @click.prevent="handleCadastro"
        >
          CADASTRAR NOVOS CUPONS
        </button>
      </div> -->
    </div>

    <CadastroCupom v-else @cadastrar="submitCupom" />
  </div>
</template>

<style scoped>
h2 {
  font-size: 30px;
  font-weight: bolder;
  color: white;
  text-transform: uppercase;
  text-align: center;
}

h2 span {
  color: #53b95d;
  cursor: pointer;
}

h2 span:hover {
  text-decoration: underline;
}

.btn-default.active {
  background-color: #4fb75a;
  color: #fff;
}

.novo-cupom {
  background-color: #60bb48 !important;
  max-width: 433px !important;
  color: #fff !important;
}

.img-arc {
  position: absolute;
  top: 50%;
  z-index: 5;
}

.row-passos {
  z-index: 6;
}

.btn-default {
  max-width: 366px;
  font-size: 23px;
  background-color: #fff;
  color: #0d54aa;
}
.btn-default.logout {
  opacity: 0.7;
}
.btn-default.logout:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .row-passos {
    gap: 20px;
  }
}
</style>
