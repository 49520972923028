<template>
  <footer>
    <!--  <header
      :style="`background-color: ${
        $route.name !== 'Home' ? '#E48F31' : '#04377e'
      }`"
    >
      <div class="container">
        <div class="row text-center justify-content-center">
          <router-link :to="{ name: 'Home' }" class="col-12 col-md-3"
            >Política de privacidade</router-link
          >
           <router-link :to="{ name: 'Regulamento' }" class="col-12 col-md-3"
            >Termos de Uso</router-link
          > 
          <router-link :to="{ name: 'Home' }" class="col-12 col-md-3"
            >Fale conosco</router-link
          >
        </div>
      </div>
    </header>-->
    <section class="pb-4">
      <div class="area-duvida pb-5 pt-3">
        <p class="text-duvida">Pintou alguma dúvida? Fale conosco!</p>
        <div class="d-flex justify-content-center">
          <button class="btn-default bg-success" @click="handleParticipe">
            Acessar!
          </button>
        </div>
      </div>
      <div class="container">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <div style="max-width: 985px">
            <div class="area-nav d-flex flex-wrap gap-3 gap-md-5">
              <router-link :to="{ name: 'Regulamento' }" class=""
                >Regulamento</router-link
              >
              <router-link :to="{ name: 'Contato' }">Dúvidas</router-link>
              <router-link :to="{ name: 'Contato' }" class=""
                >Fale conosco</router-link
              >
              <router-link :to="{ name: 'Privacidade' }" class=""
                >Política de privacidade</router-link
              >
            </div>
            <p class="text-politica">
              <small>
                Promoção válida para as lojas físicas, e-commerce e televendas
                do Brasil. Período de compras/cadastros válidos: de 02.05.2023 a
                31.08.2023. (*) Mod. Ass. Vale-brinde. Total de 121 prêmios de
                R$ 500,00, cada, entregue em créditos, via PicPay (sem função
                saque). (**) Mod. Ass. Sorteio. 4 Prêmios Mensais entregue em
                créditos, via PicPay (sem função saque) e (***) imagens
                ilustrativas de uso dos 4 prêmios finais no valor de R$
                69.000,00, cada, entregues em Certificados em barras em ouro.
                Limite de: 50 (cinquenta) Números da Sorte por CPF ou CNPJ, na
                vigência da promoção. Na compra de 3 produtos participantes o
                consumidor terá direito a receber 1 (um) Número da Sorte e,
                incluindo, ao menos 1 (um) produto acelerador poderá dobrar sua
                chance. O fator de multiplicação de Números da Sorte
                (“Acelerador”) será aplicado, uma única vez, independentemente
                da quantidade de produtos aceleradores constantes no cupom
                fiscal de compra. Consulte Regulamentos e Certificados de
                Autorização SER/ME em www.paraquemamasaborqualimax.com.br.
                Guarde os cupons originais de compra para receber o prêmio.
                Limites de contemplação da promoção: 1 (um) prêmio por CPF ou
                CNPJ na mod. ass. a vale-brinde; 1 (um) prêmio mensal por CPF ou
                CNPJ e 1 (um) prêmio final por CPF ou CNPJ na modalidade ass. a
                sorteio.
              </small>
            </p>
          </div>
          <div class="">
            <div>
              <img src="../assets/images/logo.png" alt="Logo" />
              <div>
                <a
                  class="me-2"
                  href="https://www.facebook.com/Qualimax-Food-Service-322172684957473/?modal=admin_todo_tour"
                  target="_blank"
                  title="Curta nossa página no Facebook"
                  ><img src="../assets/images/face.png" alt="Facebook"
                /></a>
                <a
                  class="me-2"
                  href="https://www.youtube.com/channel/UCKsNMo8dpGdgXQuiLFxUZsA"
                  target="_blank"
                  title="Increva-se no canal do Youtube"
                  ><img src="../assets/images/youtube.png" alt="Youtube"
                /></a>
                <a
                  class="me-2"
                  href="https://www.instagram.com/qualimaxfood/"
                  target="_blank"
                  title="Curta nossa página no Facebook"
                  ><img src="../assets/images/insta.png" alt="Instagran"
                /></a>
                <a
                  class="me-2"
                  href="https://qualimax.com.br/"
                  target="_blank"
                  title="Conheça no site"
                  ><img src="../assets/images/site.png" alt="Site Qualimax"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();
function handleParticipe() {
  router.push({ name: "Contato" });
}
</script>

<style scoped>
footer {
  background-color: #eaeaea;
  position: relative;
}

footer::before {
  content: "";
  background-image: url("../assets/images/header/rope.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 11.4px;
  width: 100vw;
  position: absolute;
  top: -10px;
  left: -5px;
}

footer header a {
  color: #fff;
}

.text-politica {
  color: #9e9e9e;
  font-size: 16px;
  line-height: 1;
  text-align: left;
}

.area-nav a {
  font-size: 23px;
  color: #0d64aa;
  font-weight: 400;
  text-transform: uppercase;
}

.area-duvida {
  background-color: #021378;
}

.text-duvida {
  font-size: 45px;
}

.btn-default {
  color: #fff;
  background-color: #60bb48 !important;
  max-width: 215px;
}

.container div {
  padding: 23px 0;
}

section .btn-faq {
  display: flex;
  justify-content: center;
  padding-top: 18px;
}

section p {
  color: #fff;
  text-align: center;
  font-size: 0.9rem;
}

/* .btn-acessar {
  color: #000000;
  font-size: 32px;
  font-weight: bolder;
  padding: 8px;
  width: 100%;
  max-width: 369px;
  background-color: #a8d32c;
  transition: all 0.3s;
}

button:hover {
  transform: scale(0.9);
} */
.img-1 {
  position: absolute;
  left: -60px;
  top: 45px;
}

.img-2 {
  position: absolute;
  right: -120px;
  top: -25px;
}

.footer-duvida {
  display: block;
  font-size: 49px;
}

.footer-fale {
  display: block;
  font-size: 49px;
  text-transform: uppercase;
  color: #fff;
}

.footer-click {
  display: block;
  font-size: 35px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .img-1 {
    position: absolute;
    left: -60px;
    top: 45px;
    display: none;
  }

  .img-2 {
    position: absolute;
    right: -120px;
    top: -25px;
    display: none;
  }

  .img-logo {
    width: 142px;
    margin: 0 !important;
  }

  .area-logo {
    gap: 20px;
  }
}
</style>
