import { createRouter, createWebHistory } from "vue-router";


import { getLocalUsuario, getLocalToken, setHeaderToken } from "../store/storage";
// import { auth } from "@/store/auth";
// const authStore = auth();

import Home from "@/views/site/Home.vue";
import Ganhadores from "@/views/site/Ganhadores.vue";
import ListaProdutos from "@/views/site/ListaProdutos.vue";
import Regulamento from "@/views/site/Regulamento.vue";
import Privacidade from "@/views/site/Privacidade.vue";
import Participe from "@/views/site/Participe.vue";
import Login from "@/views/site/Login.vue";
import Cadastro from "@/views/site/Cadastro.vue";
import Contato from "@/views/site/Contato.vue";
import Cupons from "@/views/site/Cupons.vue";
import RecoverAuth from "@/views/site/RecoverAuth.vue";


const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      layout: 'layout-client'
    }
  },
  {
    path: "/ganhadores",
    name: "Ganhadores",
    component: Ganhadores,
    meta: {
      layout: 'layout-client'
    }
  },
  {
    path: "/produtos-participantes",
    name: "ListaProdutos",
    component: ListaProdutos,
    meta: {
      layout: 'layout-client'
    }
  },
  {
    path: "/regulamento",
    name: "Regulamento",
    component: Regulamento,
    meta: {
      layout: 'layout-client'
    }
  },
  {
    path: "/politica-privacidade",
    name: "Privacidade",
    component: Privacidade,
    meta: {
      layout: 'layout-client'
    }
  },
  {
    path: "/participe",
    name: "Participe",
    component: Participe,
    meta: {
      layout: 'layout-client'
    }
  },
  {
    path: "/cadastro",
    name: "Cadastro",
    component: Cadastro,
    meta: {
      layout: 'layout-client'
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: 'layout-client'
    }
  },

  {
    path: "/cupons",
    name: "Cupons",
    component: Cupons,
    meta: {
      layout: 'layout-client'
    }
  },
  {
    path: "/contato",
    name: "Contato",
    component: Contato,
    meta: {
      layout: 'layout-client'
    }
  },
  {
    path: "/recuperar-senha/:token",
    name: "Recuperar senha",
    component: RecoverAuth,
    meta: {
      layout: 'layout-client'
    }
  },
  {
    path: "/:pathMath(.*)*",
    redirect: { path: "/home" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active"
});

router.beforeEach(async (to, from, next) => {
  let isLogged = getLocalUsuario();
  let token = getLocalToken()
  if (typeof isLogged !== "object") {
    isLogged = JSON.parse(isLogged);
  }

  // if (isLogged != null && isLogged.id != undefined) {
  //   await authStore.ActionCheckToken();
  // }

  if (!token && to.meta.auth) {
    setHeaderToken('')
    next("/");
  } else {
    next(true);
  }
});

export default router;
