<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<script setup>
import { computed, reactive, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { validaCpfCnpj } from "@/utils/validacpfCnpj";
import { storeCadastro } from "@/store/cadastro";
import ModalComponent from "@/components/ModalComponent.vue";
const isEmail = require("sane-email-validation");

const cadastroStore = storeCadastro();
const route = useRoute();
const emit = defineEmits(["handleLogin", "handleRevover"]);

const openModal = ref(false);
const validPassword = ref(false);

const recover = reactive({
  cpf: "",
});

const hasUpperCase = computed(() => {
  const upperCaseLetters = /[A-Z]/g;
  if (cadastroStore.form.password.match(upperCaseLetters)) return true;
  else return false;
});
const hasLowerCase = computed(() => {
  const lowerCaseLetters = /[a-z]/g;
  if (cadastroStore.form.password.match(lowerCaseLetters)) return true;
  else return false;
});
const hasNumber = computed(() => {
  const numbers = /[0-9]/g;
  if (cadastroStore.form.password.match(numbers)) return true;
  else return false;
});
const hasEspecial = computed(() => {
  const especial = /[-_@!.;,><=#$%&*()+]/;
  if (cadastroStore.form.password.match(especial)) return true;
  else return false;
});
const hasMinCaractere = computed(() => {
  if (cadastroStore.form.password.length >= 8) return true;
  else return false;
});

function handleEmail() {
  if (!isEmail(cadastroStore.form.email)) {
    window.__Swal.fire({
      icon: "warning",
      title: "Erro!",
      text: "Email inválido!",
    });
  }
}
async function handleLogin() {
  emit("handleLogin");
}

async function handleRecoverSend() {
  emit("handleRevover", recover.cpf);
}

function handleRecover() {
  const cpf = recover.cpf
    .replace(/\./g, "")
    .replace(/-/g, "")
    .replace(/\//g, "");
  let cpfValido = false;

  if (!cpf) {
    return window.__Swal.fire({
      text: "Campo vazio, verifique e tenta novamente",
      icon: "warning",
    });
  }
  if (cpf.length === 11 || cpf.length === 14) {
    cpfValido = validaCpfCnpj(cpf);
  } else {
    return window.__Swal.fire({
      text: "CNPJ ou CPF invalido!",
      icon: "error",
    });
  }

  if (!cpfValido) {
    return window.__Swal.fire({
      text: "CPF invalido!",
      icon: "error",
    });
  }

  handleRecoverSend();
}

function handlePassword() {
  if (route.name !== "Login") {
    const especial = /[-_@!.;,><=#$%&*()+]/;
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;

    if (
      cadastroStore.form.password.match(lowerCaseLetters) &&
      cadastroStore.form.password.match(upperCaseLetters) &&
      cadastroStore.form.password.match(numbers) &&
      cadastroStore.form.password.match(especial) &&
      cadastroStore.form.password.length >= 8
    ) {
      validPassword.value = true;
    } else {
      validPassword.value = false;
    }
  } else {
    validPassword.value = true;
  }
}

onMounted(() => {
  const elEmail = document.getElementById("email");
  elEmail.focus();
  elEmail.scrollIntoView({ behavior: "smooth" });
});
</script>

<template>
  <div class="container">
    <Section1 is-home></Section1>

    <div class="d-flex justify-content-center mb-5">
      <form class="area-form text-center" @submit.prevent>
        <p v-if="route.name === 'Recuperar senha'" class="mb-4">
          CONFIRME O EMAIL E CADASTRE A NOVA SENHA
        </p>
        <p v-else-if="route.name === 'Cadastro'" class="mb-4">
          CADASTRE SEU EMAIL E SENHA PARA CONTINUAR:
        </p>
        <p v-else class="mb-4">
          LEGAL, VOCÊ JÁ TEM CADASTRO! <br />DIGITE SEU EMAIL E SENHA PARA
          ACESSAR:
        </p>
        <label for="email" class="label-email">Email</label>
        <input
          id="email"
          v-model="cadastroStore.form.email"
          autocomplete="off"
          type="email"
          name="email"
          required
          @blur="handleEmail"
        />
        <label for="password" class="label-password">Senha</label>
        <div class="position-relative">
          <input
            id="password"
            v-model="cadastroStore.form.password"
            autocomplete="off"
            type="password"
            name="password"
            :class="{
              'mb-4': true,
              'border-danger':
                !validPassword && cadastroStore.form.password.length > 1,
            }"
            @input="handlePassword"
          />
          <small
            v-if="route.name !== 'Login'"
            :class="{
              'd-block mb-4': true,
              'text-white':
                !validPassword && cadastroStore.form.password.length > 1,
            }"
          >
            A senha deve conter
            <span :class="{ 'text-orange': !hasUpperCase }"
              >letra maiúscula</span
            >,
            <span :class="{ 'text-orange': !hasLowerCase }"
              >letra minúscula</span
            >, <span :class="{ 'text-orange': !hasNumber }">número</span>,
            <span :class="{ 'text-orange': !hasEspecial }"
              >caractere especial</span
            >
            e no mínimo
            <span :class="{ 'text-orange': !hasMinCaractere }"
              >8 caracteres</span
            >
          </small>
        </div>
        <button
          v-if="route.name === 'Login'"
          class="recover-password mb-3"
          @click.prevent="openModal = true"
        >
          Esqueci a minha senha
        </button>
        <button
          :disabled="!validPassword"
          class="btn-default"
          @click.prevent="handleLogin"
        >
          Prosseguir
        </button>
      </form>
    </div>

    <ModalComponent :open-modal="openModal" @close-modal="openModal = false">
      <div class="w-100">
        <p class="float-left">Recuperar senha</p>
      </div>
      <div class="d-flex justify-content-center">
        <div class="w-100 recuperar-area">
          <label for="cpfCnpj">Confirme o CPF ou CNPJ</label>
          <input
            id="cpf"
            v-model="recover.cpf"
            v-maska="'###.###.###-##'"
            name="cpf"
            class="mb-4 form-control"
            type="text"
          />
        </div>
      </div>
      <div class="w-100 d-flex justify-content-end align-items-center">
        <button class="btn btn-primary me-3" @click="openModal = false">
          Cancelar
        </button>
        <button class="btn btn-success" @click="handleRecover">Enviar</button>
      </div>
    </ModalComponent>
  </div>
</template>

<style scoped>
form {
  width: 100%;
  max-width: 528px;
  color: #fff;
}
form p {
  display: block;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: bold;
}
.text-orange {
  color: #fdab29;
}
.text-success {
  color: #fff !important;
}
</style>
