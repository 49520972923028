
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from 'pinia'
import Maska from 'maska'
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/css/global.css";
import VueTilt from "vue-tilt.js";
import ArgonDashboard from "./argon-dashboard";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueSweetalert2 from "vue-sweetalert2";

// import 'sweetalert2/src/sweetalert2.scss'
import 'bootstrap/dist/css/bootstrap.css'
import LayoutClient from "@/Layout/LayoutClient.vue";
import 'sweetalert2/src/sweetalert2.scss'

export const app = createApp(App);
app.use(router);
app.use(VueTilt);
app.use(VueSweetalert2);
app.use(ArgonDashboard);
app.use(Maska);
app.use(createPinia());
app.mount("#app");

app.component('LayoutClient', LayoutClient)
app.component('VSelect', vSelect)

import Swal from "sweetalert2";
window.__Swal = Swal