<script setup>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
// import Section1 from "../../components/Section1.vue";
import LoginComponent from "../../components/LoginComponent.vue";
import { validaCpfCnpj } from "../../utils/validacpfCnpj";
import { storeCadastro } from "../../store/cadastro";

import cadastro1 from '@/assets/images/cadastro-1.png'
import cadastro2 from '@/assets/images/cadastro-2.png'
import cadastro3 from '@/assets/images/cadastro-3.png'

const cadastroStore = storeCadastro();
const router = useRouter();

const step = ref(1);

const handleDisabled = computed(() => {
  if (
    !cadastroStore.form.acceptUseData ||
    !cadastroStore.form.acceptCupom ||
    !cadastroStore.form.acceptRules ||
    !cadastroStore.form.checkRecaptcha
  )
    return true;
  else return false;
});
const type = computed(() => {
  let type = "";
  if (cadastroStore.form.cpf.length > 0) {
    type = cadastroStore.form.cpf.length === 14 ? "cpf" : "cnpj";
  }
  return type;
});

function buscaCep() {
  const cep = cadastroStore.form.cep.replace(/-/g, "").replace(/\//g, "");
  if (cep.length !== 8)
    return window.__Swal.fire({
      title: "Atenção",
      icon: "warning",
      text: "confira o cep digitado!",
    });

  cadastroStore.loading = true;
  const options = {
    method: "GET",
    mode: "cors",
    cache: "default",
  };
  if (cep.length === 8) {
    fetch(`https://viacep.com.br/ws/${cep}/json/`, options).then((r) => {
      r.json().then((data) => {
        if (!data.erro) {
          const { uf } = data;
          cadastroStore.form.uf = uf;

          cadastroStore.loading = false;
        } else {
          window.__Swal.fire({
            text: "Cep não encontrado, verifique e tente novamente!",
            icon: "warning",
          });
          cadastroStore.form.cep = "";

          cadastroStore.loading = false;
        }
      });
    });
  }

  cadastroStore.loading = false;
}

function handleStep1() {
  if (!type.value) {
    window.__Swal.fire({
      text: "Preencha o CPF",
      icon: "warning",
    });
    return;
  }
  const cpfLimpo = cadastroStore.form.cpf
    .replace(/\./g, "")
    .replace(/-/g, "")
    .replace(/\//g, "");
  const cpfValido = validaCpfCnpj(cpfLimpo);

  if (!cpfValido) {
    window.__Swal.fire({
      text: "CPF ou CNPJ invalido, verifique e tenta novamente",
      icon: "warning",
    });
    return;
  } /* else if (cpfLimpo.length < 11) {
        window.__Swal.fire({
          text: "CPF menor que 11 caracter, verifique e tenta novamente",
          icon: "warning",
        });
        return;
      } */ else if (!cadastroStore.form.name) {
    window.__Swal.fire({
      text: "Nome vazio, verifique e tente novamente!",
      icon: "warning",
    });
    return;
  } else if (type.value === "cpf" && !cadastroStore.form.birthday) {
    window.__Swal.fire({
      text: "Data de nascimento vazio, verifique e tente novamente!",
      icon: "warning",
    });

    return;
  } else if (type.value === "cpf" && cadastroStore.form.birthday.length < 8) {
    window.__Swal.fire({
      text: "Data de nascimento incompleta, verifique e tente novamente!",
      icon: "warning",
    });
    return;
  } else if (!cadastroStore.form.phone) {
    window.__Swal.fire({
      text: "Telefone vazio, verifique e tente novamente!",
      icon: "warning",
    });
    return;
  } else if (!cadastroStore.form.cep) {
    window.__Swal.fire({
      text: "cep vazio, verifique e tente novamente!",
      icon: "warning",
    });
    return;
  } else if (!cadastroStore.form.uf) {
    window.__Swal.fire({
      text: "UF vazio, verifique e tente novamente!",
      icon: "warning",
    });
    return;
  }
  step.value += 1;
}

function recaptchaCallback() {
  // const vm = this;
  cadastroStore.form.checkRecaptcha = true;
}

async function handleSubmit() {
  try {
    const res = await cadastroStore.ActionCreateUsuario();

    if (res) {
      window.__Swal
        .fire({
          title: "Sucesso",
          icon: "success",
          text: "Cadastro efetuado com sucesso!",
        })
        .then(() => {
          router.push({ name: "Login" });
        });
    }
  } catch (error) {
    console.log(error);
  }
}

const stepImg = computed(() => {
  let imgPath = ''
  if (step.value === 1) {
    imgPath = cadastro1
  } else if (step.value === 2) {
    imgPath = cadastro2
  } else {
    imgPath = cadastro3
  }

  return imgPath
})

onMounted(() => {
  const googleAPI = document.createElement("script");
  googleAPI.src = "https://www.google.com/recaptcha/api.js";
  document.body.appendChild(googleAPI);
  window.recaptchaCallback = recaptchaCallback;
});
</script>

<template>
  <div :style="{ opacity: cadastroStore.loading ? '.5' : '1' }" class="mt-9 py-5 container">
    <div>
      <div class="row">
        <div class="col-md-6 d-flex justify-content-center"> <img class="img-fluid w-75 desktop promo-img-desktop"
            src="@/assets/images/nome-capanha.png" alt="Capanha" />
        </div>
        <div class="col-md-6">

          <img class="img-fluid desktop promo-img-desktop" :src="stepImg" alt="Capanha" />
        </div>
      </div>
    </div>

    <div v-if="step === 1" class="row">
      <div class="col-md-6">
        <label for="cpf">{{
          cadastroStore.form.cpf.length > 14 ? "CPF ou CNPJ" : "CPF ou CNPJ"
        }}</label>
        <input id="cpf" v-model="cadastroStore.form.cpf" v-maska="['###.###.###-##', '##.###.###/####-##']" name="cpf"
          class="mb-4 form-control" placeholder="000.000.000-00" type="text" />
      </div>
      <div class="col-md-6">
        <label for="nome">NOME COMPLETO</label>
        <input id="nome" v-model="cadastroStore.form.name" name="nome" class="mb-4 form-control" type="text" />
      </div>
      <div v-if="cadastroStore.form.cpf.length <= 14" class="col-md-6">
        <label for="data-nascimento">DATA DE NASCIMENTO</label>
        <input id="data-nascimento" v-model="cadastroStore.form.birthday" v-maska="'##/##/####'" name="data-nascimento"
          class="mb-4 form-control" type="text" placeholder="00/00/0000" />
      </div>
      <div class="col-md-6">
        <label for="phone">TELEFONE</label>
        <input id="phone" v-model="cadastroStore.form.phone" v-maska="'(##) #####-####'" name="phone"
          class="mb-4 form-control" type="text" placeholder="(00) 00000-0000" />
      </div>
      <div class="col-md-6">
        <label for="cep">CEP</label>
        <input id="cep" v-model="cadastroStore.form.cep" v-maska="'#####-###'" name="cep" class="mb-4 form-control"
          type="text" placeholder="00000-000" @blur="buscaCep" />
      </div>
      <div class="col-md-6">
        <label for="uf">UF</label>
        <input id="uf" v-model="cadastroStore.form.uf" name="uf" class="mb-4 form-control" type="text" />
      </div>
    </div>
    <LoginComponent v-if="step === 2" @handleLogin="step = 3" />
    <div v-show="step === 3">
      <form class="d-flex justify-content-center flex-column align-items-center" @submit.prevent="handleSend">
        <div class="area-checks">
          <label>
            <div>
              <input v-model="cadastroStore.form.acceptUseData" type="checkbox" />
            </div>
            <span>*</span> ESTOU CIENTE E CONCEDO PERMISSÃO DA UTILIZAÇÃO DE
            MEUS DADOS PARA EFEITO DA PROMOÇÃO, APURAÇÃO E PREMIAÇÃO.
          </label>
          <label>
            <div>
              <input v-model="cadastroStore.form.acceptCupom" type="checkbox" />
            </div>
            <span>*</span> ESTOU CIENTE DE QUE PRECISO GUARDAR OS CUPONS
            FISCAIS CADASTRADOS.
          </label>
          <label>
            <div>
              <input v-model="cadastroStore.form.acceptRules" type="checkbox" />
            </div>
            <span>*</span> LI E CONCORDO COM OS REGULAMENTOS.
          </label>
          <label>
            <div>
              <input v-model="cadastroStore.form.acceptInfo" type="checkbox" />
            </div>
            ACEITO RECEBER INFORMAÇÕES DA QUALIMAX.
          </label>
          <label>
            <div>
              <input v-model="cadastroStore.form.acceptWhatsapp" type="checkbox" />
            </div>
            ACEITO RECEBER MENSAGENS NO NÚMERO DE WHATSAPP CADASTRADO.
          </label>
          <p class="required py-4">*CAMPOS DE PREENCHIMENTO OBRIGATÓRIO.</p>
          <h2>
            SUAS INFORMAÇÕES SÃO CONFIDENCIAIS E SERÃO UTILIZADAS SOMENTE PARA
            A PROMOÇÃO.
          </h2>
        </div>
        <p class="warning py-4">
          <small>Ao se inscrever nesta promoção, os participantes autorizam
            coleta, armazenamento, tratamento, utilização e compartilhamento
            pela Promotora dos dados estritamente necessários para a
            operacionalização e divulgação da própria promoção e entrega dos
            prêmios, nos limites de legislação aplicável, sem nenhum ônus a
            si; podendo ainda a Promotora realizar a formação de cadastro e/ou
            banco de dados, informando que os dados coletados estarão
            indicados no site promocional e que os utilizará para os fins de
            envio de comunicação publicitária pela Promotora durante o período
            da promoção e por 12 meses contados da data de encerramento da
            promoção.
          </small>
        </p>
        <p class="warning">
          <small>A promotora da ação poderá, em caráter temporário, durante o
            período da promoção e por 12 meses contados da data de
            encerramento, compartilhar o banco de dados de participantes a
            parceiros encarregados da entrega de prêmios e atividades afins
            (como, exemplificadamente, mas não se limitando a, pesquisas),
            ficando claro que esta cessão não implica em transferência do
            banco de dados correspondente.
          </small>
        </p>
        <!-- <div
          class="d-flex justify-content-center"
          style="width: 90%; max-width: 523px"
        >
          <input
            type="text"
            name="qi"
            id="qi"
            v-model="cadastroStore.form.infoPromo"
            placeholder="Conta pra gente, como ficou sabendo da promoção?"
            class="input-indicacao my-4"
          />
        </div> -->
        <div class="g-recaptcha mt-4" data-sitekey="6LeZnsElAAAAADBEnoPJYTZcmDbWqO5qMi7X_NSc"
          data-callback="recaptchaCallback" data-expired-callback="recaptchaCallback"></div>
      </form>
    </div>
    <div class="d-flex justify-content-center">
      <button v-if="step === 1" class="btn-default mb-4" @click.prevent="handleStep1">
        PROSSEGUIR
      </button>
      <button v-if="step === 3" :disabled="handleDisabled" type="submit" class="btn-default mt-4"
        :class="{ disabled: handleDisabled }" @click.prevent="handleSubmit">
        Concluir cadastro
      </button>
    </div>


  </div>
</template>

<style scoped>
h2 {
  font-size: 30px;
  font-weight: bolder;
  color: white;
  text-transform: uppercase;
  text-align: center;
}

h2 span {
  color: #53b95d;
  cursor: pointer;
}

h2 span:hover {
  text-decoration: underline;
}

.btn-participe {
  background-color: #4fb75a;
  color: white;
  font-weight: bolder;
  font-size: 40px;
  padding: 8px 45px;
  z-index: 6;
}

.img-arc {
  position: absolute;
  top: 50%;
  z-index: 5;
}

.area-btn-participe {
  z-index: 6;
}

.row-passos {
  z-index: 6;
}

.area-checks {
  width: 100%;
  max-width: 583px;
  color: #fff;
}

.area-checks label {
  text-transform: uppercase;
  font-size: 15px !important;
  color: #fff !important;
  font-weight: bold !important;
  display: flex !important;
  align-items: center !important;
  cursor: pointer !important;
  text-align: left;
}

.area-checks label span {
  color: #ec8a23;
  margin-right: 5px;
  margin-top: -22px;
}

.required {
  color: #61AF33;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.area-checks h2 {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.warning {
  color: #fff;
  max-width: 817px;
  text-align: center;
  line-height: 1;
}

.input-indicacao {
  max-width: 523px;
  background-color: #092850;
  border-radius: 15px;
  color: #fff;
}

.input-indicacao::placeholder {
  color: #fff;
  font-weight: bold;
}

@media (max-width: 768px) {
  .row-passos {
    gap: 20px;
  }
}
</style>
