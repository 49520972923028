<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<script setup>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { auth } from "../../store/auth";
import { validaCpfCnpj } from "../../utils/validacpfCnpj";
import ModalComponent from "../../components/ModalComponent.vue";
// import Section1 from "../../components/Section1.vue";
const isEmail = require("sane-email-validation");

const router = useRouter();
const authStore = auth();
const loading = ref(false);
const openModal = ref(false);
const showPass = ref(false);
const login = reactive({
  email: "",
  password: "",
});
const recover = reactive({
  cpf: "",
});

function handleEmail() {
  if (!isEmail(login.email)) {
    window.__Swal.fire({
      icon: "warning",
      title: "Erro!",
      text: "Email inválido!",
    });
  }
}
async function handleLogin() {
  try {
    loading.value = true;
    const body = new FormData();
    body.append("email", login.email);
    body.append("password", login.password);
    await authStore.ActionDoLogin(body);
    await authStore.ActionGetProfile();
    router.push({ name: "Cupons" });
  } catch (error) {
    console.log(error);
    if (error.status !== 401) {
      if (error.data) {
        window.__Swal.fire({
          icon: "error",
          title: error.data.error.title || "Oops...",
          text: error.data.error.message,
        });
      } else {
        window.__Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ocorreu um erro inesperado, favor contactar o suporte.",
        });
      }
    }
  } finally {
    loading.value = false;
  }
}

async function handleRecoverSend() {
  const cpf = recover.cpf
    .replace(/\./g, "")
    .replace(/-/g, "")
    .replace(/\//g, "");
  let key = cpf.length === 11 ? "cpf" : "cnpj";

  try {
    const res = await authStore.ActionForgotPassword({
      [key]: cpf,
    });

    window.__Swal.fire({
      icon: res.data.success ? "success" : "warning",
      text: res.data.message,
    });
  } catch (error) {
    console.log(error);
  }finally{
    openModal.value = false
  }
}

function handleRecover() {
  const cpf = recover.cpf
    .replace(/\./g, "")
    .replace(/-/g, "")
    .replace(/\//g, "");
  let cpfValido = false;

  if (!cpf) {
    return window.__Swal.fire({
      text: "Campo vazio, verifique e tenta novamente",
      icon: "warning",
    });
  }
  if (cpf.length === 11 || cpf.length === 14) {
    cpfValido = validaCpfCnpj(cpf);
  } else {
    return window.__Swal.fire({
      text: "CNPJ ou CPF invalido!",
      icon: "error",
    });
  }

  if (!cpfValido) {
    return window.__Swal.fire({
      text: "CPF invalido!",
      icon: "error",
    });
  }

  handleRecoverSend();
}

function handleNovoCadastro() {
  // router.push({ name: 'Cadastro' })
  window.__Swal.fire({
      icon: "warning",
      title: "Atenção!",
      text: "Promoção encerrada, acompanhe os ganhadores!",
       }).then(() => {
        router.push({ name: "Ganhadores" });
    })
}
</script>

<template>
  <!-- <Section1 is-home></Section1> -->
  <div class="container py-5 mt-7">
    <div class="row">
      <div
        class="col-md-6 d-flex justify-content-center align-items-center flex-column"
      >
        <img
          class="img-fluid w-75 desktop promo-img-desktop"
          src="@/assets/images/nome-capanha.png"
          alt="Capanha"
        />
        <div class="area-cadastre mt-3">
          <h5>Não é cadastrado?</h5>
          <p class="text-primary-">
            Clique no botão abaixo e informe seus dados pessoais.
          </p>

          <button
            class="btn-default mt-6"
            @click.prevent="handleNovoCadastro"
          >
            Cadastrar
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-center mb-5">
          <form class="area-form text-center" @submit.prevent>
            <img
              class="img-fluid"
              src="@/assets/images/faca-login.png"
              alt="Login"
            />
            <h5>Ja tem cadastro?</h5>
            <p class="text-primary- mb-4">Insira seus dados abaixo.</p>
            <!-- <label for="email" class="label-email">Email</label> -->
            <input
              id="email"
              v-model="login.email"
              autocomplete="off"
              type="email"
              name="email"
              required
              placeholder="*Email"
              @blur="handleEmail"
              @keydown.enter="handleLogin"
            />
            <!-- <label for="password" class="label-password">Senha</label> -->
            <div class="position-relative mt-4">
              <input
                id="password"
                v-model="login.password"
                autocomplete="off"
                :type="showPass ? 'text' : 'password'"
                name="password"
                :class="{
                  'mb-4': true,
                }"
                placeholder="*Senha"
                @keydown.enter="handleLogin"
              />
              <span
                class="show-password pe-3 pt-1"
                @click.prevent="showPass = !showPass"
              >
                <i
                  v-if="showPass"
                  class="fa-sharp fa-solid fa-eye-slash text-primary-"
                ></i>
                <i v-else class="fa-sharp fa-solid fa-eye text-primary-"></i>
              </span>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <button
                v-if="loading"
                style="order: 1"
                class="btn-default"
                @click.prevent
              >
                <div class="spinner-border text-white" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
              <button
                v-else
                style="order: 1"
                class="btn-default"
                @click.prevent="handleLogin"
              >
                Entrar
              </button>
              <button
                class="recover-password order-0 mb-3 text-primary-"
                @click.prevent="openModal = true"
              >
                <img src="@/assets/images/key.png" alt="Chave" /> Esqueci a
                minha senha
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <ModalComponent :open-modal="openModal" @close-modal="openModal = false">
      <div class="w-100">
        <p class="float-left">Recuperar senha</p>
      </div>
      <div class="d-flex justify-content-center">
        <div class="w-100 recuperar-area">
          <label for="cpfCnpj">Confirme o CPF ou CNPJ</label>
          <input
            id="cpf"
            v-model="recover.cpf"
            v-maska="['###.###.###-##', '##.###.###/####-##']"
            name="cpf"
            class="mb-4 form-control"
            type="text"
          />
        </div>
      </div>
      <div class="w-100 d-flex justify-content-end align-items-center">
        <button class="btn btn-primary me-3" @click="openModal = false">
          Cancelar
        </button>
        <button class="btn btn-success" @click="handleRecover">Enviar</button>
      </div>
    </ModalComponent>
  </div>
</template>

<style scoped>
.area-cadastre {
  max-width: 438px;
  text-align: center;
}

h5 {
  font-size: 50px;
  color: white;
  font-weight: 400;
  margin-bottom: 0;
}

h5 + p {
  font-size: 30px;
  letter-spacing: -0.5px;
  line-height: 1;
}

.recover-password {
  color: #fef176;
}

.show-password {
  position: absolute;
  right: 0;
  top: 15px;
}

form {
  width: 100%;
  max-width: 528px;
  color: #fff;
}

form p {
  display: block;
  font-size: 30px;
  font-weight: 400;
}
</style>
