import { defineStore } from 'pinia'
import { http } from '@/services/api';

const columns = [{
    dataField: 'data',
    dataType: 'string',
    caption: 'DATA:',
    width: '22%',
    headerAlign: 'center',
    dataAlign: 'center',
},
{
    dataField: 'n_cupom',
    dataType: 'string',
    caption: 'Nº CUPOM:',
    width: '25%',
    headerAlign: 'center',
    dataAlign: 'center',
},
{
    dataField: 'numbers',
    dataType: 'string',
    caption: 'Nº DA SORTE:',
    width: '25%',
    headerAlign: 'center',
    dataAlign: 'center',
},
{
    dataField: 'instantaneo',
    dataType: 'string',
    caption: 'INSTANTÂNEO:',
    width: '28%',
    headerAlign: 'center',
    dataAlign: 'center',
},]
export const storeCupons = defineStore('cupons', {
    state: () =>
    ({
        loading: false,
        columns: [],
        listCupons: [{
            campo1: '25/2/2022',
            campo2: '23423423',
            campo3: '09.0000/09.09823',
            campo4: 'NÃO GANHOU.',
        },]
    }),
    actions: {
        ActionGetCupons() {
            const url = 'getCupons'
            return http.get(url).then(res => {
                if (res.data.assinaturas.length > 0) {
                    this.columns = columns
                } else {
                    this.columns = [{
                        dataField: 'data',
                        dataType: 'string',
                        caption: 'NENHUM CUPOM CADASTRADO',
                        width: '100%',
                        headerAlign: 'center',
                        dataAlign: 'center',
                    }]
                }
                this.listCupons = res.data.assinaturas
            })
        },
        ActionPostCupom(payload) {
            const url = 'sendCupom'
            return http.post(url, payload)
        },

    }
})