<script setup>
import { useRouter } from "vue-router";
import FormCPF from "../../components/FormCPF.vue";
// import Section1 from "../../components/Section1.vue";
import { validaCpfCnpj } from "../../utils/validacpfCnpj";
import { http } from "../../services/api";
import { storeCadastro } from "../../store/cadastro";

const cadastroStore = storeCadastro();
const router = useRouter();
async function handleSubmitCPF(cpf) {
  const cpfLimpo = cpf.replace(/\./g, "").replace(/-/g, "").replace(/\//g, "");
  let cpfValido = false;
  if (cpfLimpo.length === 11 || cpfLimpo.length === 14) {
    cpfValido = validaCpfCnpj(cpfLimpo);
  } else
    return window.__Swal.fire({
      text: "CNPJ ou CPF invalido!",
      icon: "error",
    });

  if (!cpfValido)
    return window.__Swal.fire({
      text: "CPF ou CNPJ invalido!",
      icon: "error",
    });

  try {
    const res = await http.post("checkCpf", {
      [cpfLimpo.length === 11 ? "cpf" : "cnpj"]: cpfLimpo,
      [cpfLimpo.length === 11 ? "cnpj" : "cpf"]: "",
    });
    if (!res.data.success) {
      cadastroStore.form.cpf = cpfLimpo;
      // router.push({ name: "Cadastro" });
       window.__Swal.fire({
      icon: "warning",
      title: "Atenção!",
      text: "Promoção encerrada, acompanhe os ganhadores!",
       }).then(() => {
        router.push({ name: "Ganhadores" });
    })
    } else if (res.data.success) {
      router.push({ name: "Login" });
    }
  } catch (error) {
    console.log(error);
    window.__Swal.fire({
      icon: "error",
      title: "Erro!",
      text: "Erro ao checar o CPF!",
    });
  }
}
</script>

<template>
  <!-- <Section1 is-home></Section1> -->

  <div class="mt-5 mt-md-9 py-5">
    <FormCPF class="mt-5" @submitCPF="handleSubmitCPF"/>
  </div>
</template>

<style scoped>
h2 {
  font-size: 30px;
  font-weight: bolder;
  color: white;
  text-transform: uppercase;
  text-align: center;
}
h2 span {
  color: #53b95d;
  cursor: pointer;
}
h2 span:hover {
  text-decoration: underline;
}

.btn-participe {
  background-color: #4fb75a;
  color: white;
  font-weight: bolder;
  font-size: 40px;
  padding: 8px 45px;
  z-index: 6;
}

.img-arc {
  position: absolute;
  top: 50%;
  z-index: 5;
}
.area-btn-participe {
  z-index: 6;
}
.row-passos {
  z-index: 6;
}

@media (max-width: 768px) {
  .row-passos {
    gap: 20px;
  }
}
</style>
