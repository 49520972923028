<template>
  <main class="main-content position-relative max-height-vh-100 h-100">
    <component :is="layout">
      <router-view />
    </component>
  </main>
</template>
<script>
// import Sidenav from "./examples/Sidenav";
// import Configurator from "@/examples/Configurator.vue";
// import Navbar from "@/examples/Navbars/Navbar.vue";
// import AppFooter from "@/examples/Footer.vue";
import { mapState } from "vuex";
export default {
  name: "App",
  components: {
    // Login,
    // Sidenav,
    // Configurator,
    // Navbar,
    // AppFooter
  },
  computed: {
    ...mapState([
      "layout",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),

    layout() {
      return this.$route.meta.layout || "Layout-default";
    },
  },
  mounted() {},
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  /* background-image: url("./assets/images/bg-body.png"); */
  /* background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; */
  /* font-family: 'SuezOne-Regular'; */
  background-color: #0d55aa !important;
}

p {
  margin: 0 !important;
}

button {
  background-color: transparent;
  border: 0;
}

button:disabled {
  opacity: 0.7;
}

label {
  text-transform: uppercase;
  font-size: 20px !important;
  color: #63bb2d !important;
  font-weight: bold;
  display: block !important;
  text-align: center;
}

.flex-1 {
  flex: 1;
}

.bg-blue-2 {
  background-color: #0d2148 !important;
}

.bg-orange {
  background-color: #fda830 !important;
}

.vs__dropdown-toggle {
  border: 0 !important;
}

.cursor-auto {
  cursor: auto;
}

html,
body {
  color: #000000 !important;
}

input {
  height: 60px;
  font-size: 20px !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  text-align: center;
  width: 100%;
  border-color: transparent;
}

input::placeholder {
  color: #bcbcbc !important;
}

input[type="checkbox"] {
  width: 33px;
  height: 33px;
  margin: 0;
  margin-right: 16px;
}

input[type="datetime-local"],
input[type="date"] {
  outline: 0;
  position: relative;
  width: 100%;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="date"]::after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("./assets/icons/calendar.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  text-decoration: none !important;
}

.btn-default {
  color: #fff;
  font-size: 28px;
  font-weight: 400;
  padding: 5px 25px;
  background-color: #60bb48;
  transition: all 0.3s;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
}

.btn-default:hover {
  transform: scale(0.9);
}

.disabled {
  opacity: 0.5;
}

.swal2-checkbox {
  display: none !important;
}

.vs__open-indicator {
  margin-top: -18px;
}

.vs__clear {
  margin-top: -21px;
}

.anima-float {
  animation: float 1s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@media (max-width: 992px) {
  label {
    font-size: 16px !important;
  }

  .vs__open-indicator {
    display: none !important;
  }

  .vs__clear {
    margin-top: -70px;
  }
}

@media (max-width: 510px) {
  .btn-default {
    font-size: 20px;
  }
}

.text-primary- {
  color: #61af33 !important;
}

.btn-participar {
  background-color: #0d58aa;
  color: #fff;
}
.btn-participar-header {
  background-color: #021378;
  color: #fff;
}
</style>
