<script setup>
import { useRoute } from "vue-router";
const route = useRoute();
const props = defineProps({
  isGanhadores: { type: Boolean, default: false },
  isHome: { type: Boolean, default: false },
  isProdutosLista: { type: Boolean, default: false },
  isRegulamento: { type: Boolean, default: false },
  isPrivacidade: { type: Boolean, default: false },
});
</script>
<template>
  <div class="section-1">
    <div class="container position-relative">
      <div
        class="row"
        :class="[
          route.name === 'Contato' || route.name === 'Privacidade'
            ? 'justify-content-center'
            : 'justify-content-between',
        ]"
      >
        <!-- <div class="logo-float text-end">
          <img src="../assets/images/logo_assai.png" alt="Assai" />
        </div> -->
        <div
          class="d-flex justify-content-center align-items-center m-auto"
          :class="[props.isHome ? 'col-md-4' : 'col-md-6']"
        >
          <div class="position-relative">
            <!-- <img
          class="img-fluid ponteiro"
          src="../assets/images/ponteiro.png"
          alt="Ponteiro"
        /> -->
            <img
              class="img-fluid mobile"
              src="../assets/images/nome-capanha.png"
              alt="Capanha"
            />
            <img
              class="img-fluid w-95 desktop promo-img-desktop"
              src="../assets/images/nome-capanha.png"
              alt="Capanha"
            />
            <!-- <img v-if="props.isHome" class="img-fluid produtos" src="../assets/images/produtos.png" alt="Produtos" /> -->

            <!-- images anima -->
            <!-- <div class="desktop">
          <img
            class="img-0 img-moeda-0"
            src="../assets/images/img-anima-0.png"
            alt=""
          />
          <img
            class="img-1 img-moeda-1"
            src="../assets/images/img-anima-2.png"
            alt=""
          />
          <img
            class="img-2 img-moeda-2"
            src="../assets/images/img-anima-1.png"
            alt=""
          />
          <img class="img-3" src="../assets/images/img-anima-3.png" alt="" />
          <img class="img-4" src="../assets/images/img-anima-4.png" alt="" />
          <img class="img-5" src="../assets/images/img-anima-5.png" alt="" />
          <img class="img-6" src="../assets/images/img-anima-6.png" alt="" />
          <img class="img-7" src="../assets/images/img-anima-7.png" alt="" />
          <img class="img-8" src="../assets/images/img-anima-8.png" alt="" />
        </div> -->
            <!-- end images anima -->
          </div>
        </div>
        <div
          v-if="props.isHome"
          class="col-md-4 d-flex align-items-center justify-content-center"
          :class="{ 'position-img2': props.isHome }"
        >
          <img
            class="img-fluid produtos d-none d-md-block"
            src="../assets/images/produtos.png"
            alt="Produtos"
          />
          <img
            class="img-fluid produtos d-md-none mb-4"
            src="../assets/images/produtos-mobile.png"
            alt="Produtos"
          />
        </div>
        <div
          v-if="route.name !== 'Contato' && route.name !== 'Cupons' && route.name !== 'Privacidade'"
          class="d-flex align-items-center justify-content-center anima-float mb-4 mb-md-0"
          :class="[props.isHome ? 'col-md-4' : 'col-md-6']"
        >
          <img
            v-if="props.isHome"
            class="img-fluid"
            src="../assets/images/premios.png"
            alt="Prêmio"
          />

          <img
            v-if="props.isGanhadores"
            class="img-fluid"
            src="../assets/images/text-ganhadores.png"
            alt="Ganhadores"
          />
          <img
            v-if="props.isProdutosLista"
            class="img-fluid mb-4"
            src="../assets/images/produtos-partipantes.png"
            alt="Produtos"
          />
          <img
            v-if="props.isRegulamento"
            class="img-fluid"
            src="../assets/images/regulamento.png"
            alt="Regulamento"
          />
        </div>
      </div>

      <div v-if="props.isHome" class="row text-center desktop">
        <div>
          <img
            class="img-fluid"
            src="../assets/images/text-dobre.png"
            alt="Dobre suas chances"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.section-1 {
  /* background-image: url("@/assets/images/bg-section-1.png"); */
  background-color: #0d55aa;
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: center center;
  padding-top: 150px;
}

.subtitle {
  font-size: 24px;
  font-weight: bold;
}

.promo-img-desktop {
  max-width: 550px;
}

.position-img2 {
  margin-left: -150px;
}

.ponteiro {
  position: absolute;
  left: 58%;
  animation: vibrate-1 0.1s linear infinite;
  z-index: -1;
}

.img-0 {
  position: absolute;
  top: 105px;
  left: 107px;
}

.img-1 {
  position: absolute;
  top: 24px;
  left: 121px;
}

.img-2 {
  position: absolute;
  top: 147px;
  left: -5px;
}

.img-3 {
  position: absolute;
  top: 30px;
  left: 232px;
  animation: fadeIn 1.8s ease-in-out infinite;
}

.img-4 {
  position: absolute;
  top: 14px;
  right: 138px;
  animation: fadeIn 2s ease-in-out infinite;
}

.img-5 {
  position: absolute;
  top: 14px;
  right: 60px;
  animation: fadeIn 0.8s ease-in-out infinite;
}

.img-6 {
  position: absolute;
  top: 48px;
  right: -5px;
  animation: fadeIn 0.9s ease-in-out infinite;
}

.img-7 {
  position: absolute;
  top: 211px;
  right: -17px;
  animation: fadeIn 1.8s ease-in-out infinite;
}

.img-8 {
  position: absolute;
  top: 277px;
  left: 92px;
  animation: fadeIn 2.8s ease-in-out infinite;
}

.produtos {
  /* margin-top: -98px;
  margin-left: 39px; */
}

.desktop {
  display: initial;
}

.mobile {
  display: none;
}

.scooter-mobile {
  display: none;
}

.premio-desktop {
  display: initial;
}

.logo-float {
  position: absolute;
  top: -82px;
  right: 159px;
}

.logo-float img {
  width: 130px;
}

@keyframes vibrate-1 {
  0% {
    transform: rotate(2deg);
  }

  100% {
    transform: rotate(-1deg);
  }
}

@media (max-width: 1399px) {
  .ponteiro {
    position: absolute;
    left: 58%;
    top: 1%;
    animation: vibrate-1 0.1s linear infinite;
    width: 80px;
  }
}

@media (max-width: 1399px) {
  .ponteiro {
    position: absolute;
    left: 58%;
    top: 2%;
    animation: vibrate-1 0.1s linear infinite;
    width: 60px;
  }
}

@media (max-width: 1024px) {
  .produtos {
    margin-top: 0;
  }

  .position-img2 {
    margin-left: 0px;
  }

  .desktop,
  .ponteiro {
    display: none;
  }

  .mobile {
    display: initial;
  }
}

.img-moeda-0 {
  animation: fadeIn 2s ease-in-out infinite;
}

.img-moeda-1 {
  animation: fadeIn 1s ease-in-out infinite;
}

.img-moeda-2 {
  animation: fadeIn 1.5s ease-in-out infinite;
}

@keyframes fadeIn {
  0% {
    transform: translateY(-80%);
    opacity: 0;
  }

  50% {
    /* transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0); */
    opacity: 1;
  }

  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .section-1 {
    /* background-image: url("@/assets/images/bg-section1-mobile.png"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding-top: 93px;
  }

  .logo-float {
    display: none;
  }

  .scooter-mobile {
    display: initial;
  }

  .premio-desktop {
    display: none;
  }
}
</style>
