<script setup>
import { storeProdutos } from "../../store/produtosParticipantes";

import ListagemComponent from "../../components/ListagemComponent.vue";
import Section1 from "../../components/Section1.vue";
import { onMounted } from "vue";

const produtosStore = storeProdutos();

onMounted(() => {
  produtosStore.ActionGetProdutos();
});
</script>

<template>
  <div class="">
    <Section1 is-produtos-lista></Section1>
  </div>

  <div class="container my-5">
    <div class="col-12">
        <ListagemComponent
          title="Confira os produtos participantes:"
          :columns="produtosStore.columns"
          :rows="produtosStore.produtos"
        />
    </div>
  </div>
</template>

<style scoped>
h2 {
  font-size: 30px;
  font-weight: bolder;
  color: white;
  text-transform: uppercase;
  text-align: center;
}
h2 span {
  color: #53b95d;
  cursor: pointer;
}
h2 span:hover {
  text-decoration: underline;
}

.btn-participe {
  background-color: #4fb75a;
  color: white;
  font-weight: bolder;
  font-size: 40px;
  padding: 8px 45px;
  z-index: 6;
}

.img-arc {
  position: absolute;
  top: 50%;
  z-index: 5;
}
.area-btn-participe {
  z-index: 6;
}
.row-passos {
  z-index: 6;
}

@media (max-width: 768px) {
  .row-passos {
    gap: 20px;
  }
}
</style>
