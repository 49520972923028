<template>
  <div class="aviso-wrapper">
    <div class="aviso-cookies card">
      <div class="border-0">
        <h2 class="mb-3">Este website utiliza cookies</h2>
        <p class="mb-3 m-auto">
          As configurações de cookies neste site são definidas para que possamos
          dar-lhe a melhor experiência enquanto estiver aqui. Se desejar, você
          pode alterar as configurações de cookies a qualquer momento em seu
          navegador. Saiba mais. <a href=""><strong>Saiba mais</strong>.</a>
        </p>
        <div class="d-flex justify-content-center">
          <button
            class="btn-default bg-blue-2 text-white rounded-pill"
            @click.prevent="handleClick"
          >
            Ok, eu entendi
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageCookies",
  methods: {
    handleClick() {
      this.$emit("handleClick");
    },
  },
};
</script>

<style scoped>
.aviso-cookies {
  width: 100%;
  max-width: 962px;
  background-color: #fff;
  padding: 50px 15px;
  border-radius: 50px;
}
h2 {
  font-size: 25px;
  color: #54b95e;
  font-weight: bold;
  text-align: center;
}
p {
  color: #0d2148;
  font-size: 12px;
  text-align: center;
  max-width: 530px;
  margin: auto;
}
.btn-default {
  max-width: 230px;
  margin: auto;
  font-size: 20px;
  font-weight: bold;
}

.aviso-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
