<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<template>
  <div class="area-header">
    <img
      class="logo-header desktop"
      src="../assets/images/header/logo-header.png"
      alt="logo"
    />

    <div
      class="bg-white d-flex justify-content-end align-items-center w-100 area-1"
    >
      <router-link
        :to="{ name: 'Contato' }"
        >Dúvidas</router-link
      >
      <router-link
        :to="{ name: 'Regulamento' }"
        >Regulamento</router-link
      >
    </div>
    <header>
      <nav id="nav" :class="{ active: active }">
        <div class="d-flex justify-content-between showMenu">
          <button id="btn-mobile" @click.prevent="toggleMenu">
            <span id="hamburger"></span>
          </button>
          <div class="flex-1 d-flex justify-content-center d-none">
            <button
              class="btn-default rounded-pill"
              @click.prevent="$router.push({ name: 'Participe' })"
            >
              Participe aqui
            </button>
          </div>
        </div>
        <ul class="menu">
          <!-- <li style="border-color: transparent" class="d-lg-none">
            <div class="d-flex justify-content-between p-3">
              <button id="btn-mobile" @click.prevent="toggleMenu">
                <span id="hamburger" class=""></span>
              </button>
            </div>
          </li> -->

          <li>
            <router-link
              :to="{ name: 'Home' }"
              :class="{ ativo: route.fullPath.split('/')[1] === 'home' }"
              @click.native="toggleMenu"
              >INICIO</router-link
            >
          </li>
          <li>
            <router-link
              :class="{ ativo: $route.fullPath.includes('#comoParticipar') }"
              to="home#comoParticipar"
              @click.native="scrollIntoView"
              >COMO PARTICIPAR
            </router-link>
          </li>
          <li>
            <router-link
              to="home#premios"
              :class="{ ativo: $route.fullPath.includes('#premios') }"
              @click.native="scrollIntoView"
              >PRÊMIOS</router-link
            >
          </li>
          <li>
            <router-link
              :class="{
                ativo:
                  route.fullPath.split('/')[1] === 'produtos-participantes',
              }"
              :to="{ name: 'ListaProdutos' }"
              @click.native="toggleMenu"
              >PRODUTOS</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'Ganhadores' }"
              :class="{
                ativo: route.name === 'Ganhadores',
              }"
              @click.native="toggleMenu"
              >GANHADORES</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'Regulamento' }"
              :class="{
                ativo: route.fullPath.split('/')[1] === 'regulamento',
              }"
              @click.native="toggleMenu"
              >REGULAMENTO</router-link
            >
          </li>
          <li class="participe-mobile">
            <button class="btn-default btn-participar" @click="handleParticipe">
              PARTICIPAR
            </button>
          </li>
          <li class="d-none d-md-block">
            <button
              class="btn-default btn-participar-header"
              @click="router.push({ name: 'Login' })"
            >
            <span style="font-size: 20px; text-transform: lowercase;">Já tenho cadastro</span>
            </button>
          </li>
        </ul>
      </nav>
    </header>
  </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { auth } from "../store/auth";
const authStore = auth();
const router = useRouter();
const route = useRoute();
const props = defineProps({
  menuActive: { type: Boolean },
});

const active = ref(true);

function watchScreenWidth() {
  const widthScreen = window.innerWidth;
  if (widthScreen < 1770) active.value = false;
  else active.value = true;
}
function fadeMenu() {
  // const widthScreen = window.innerWidth;
  // const nav = document.getElementById("nav");
  // if (widthScreen > 768 && nav) {
  // const currentScrollpos = window.pageYOffset;
  // const header = document.querySelector("header");
  // if (currentScrollpos >= 100)
  //   header.style.background = "rgba(2, 34, 75, .9)";
  // else header.style.background = "initial";
  // }:
}

function handleParticipe() {
  if (authStore.token) {
    router.push({ name: "Cupons" });
  } else {
    window.__Swal.fire({
      icon: "warning",
      title: "Atenção!",
      text: "Promoção encerrada, acompanhe os ganhadores!",
       }).then(() => {
        router.push({ name: "Ganhadores" });
    })
    // router.push({ name: "Participe" });
  }
  toggleMenu()
}

function scrollIntoView(evt) {
  evt.preventDefault();
  const href = evt.target.getAttribute("href");
  const idEl = href.split("#")[1];
  const el = href ? document.querySelector("#" + idEl) : null;

  if (route.name === "Home" && el) {
    const alignPx = idEl === "premios" ? 0 : 170;
    window.scrollTo({ top: el.offsetTop - alignPx, behavior: "smooth" });
  } else {
    router.push({ path: href });

    setTimeout(() => {
      const elemento = document.getElementById(idEl);
      if (elemento) {
        window.scrollTo({
          top: elemento.offsetTop - 170,
          behavior: "smooth",
        });
      }
    });
  }
  active.value = false;
}
function toggleMenu() {
  active.value = !active.value;
  // const nav = document.querySelector("#nav");
  // nav.classList.toggle("active");
}

onMounted(() => {
  window.addEventListener("resize", watchScreenWidth);
  active.value = props.menuActive;

  window.addEventListener("scroll", fadeMenu);
});

onUnmounted(() => {
  window.removeEventListener("scroll", fadeMenu);
});
</script>

<style scoped>
.showMenu {
  display: none;
}
.area-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 137px;
  transition: all 0.8s ease-in-out;
  z-index: 102;
}

.area-1 {
  height: 60px;
  gap: 20px;
  padding: 0 25px;
}
.area-1 a {
  font-weight: 400 !important;
  font-size: 25px;
  color: #959595 !important;
}

header {
  /* padding-top: 25px;
  padding-bottom: 25px; */
  /* background-image: url("../assets/images/bg-header.png"); */
  background-color: #61af33;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  height: 84px;
  position: relative;
}

header::after {
  content: "";
  background-image: url("../assets/images/header/rope.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 11.4px;
  width: 100vw;
  position: absolute;
  bottom: -10px;
}

ul {
  margin: 0;
}

nav {
  width: 100%;
  display: flex;
  justify-content: end;
  padding-right: 34px;
}

.logo-header {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 196;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.menu li {
  text-transform: uppercase;
  /* max-width: 126px; */
  display: block;
  text-align: center;
  line-height: 1.1;
  transition: all 0.3s;
  white-space: nowrap;
  font-size: 18px;
}

#btn-mobile {
  display: none;
}

#hamburger {
  border-top: 2px solid;
  width: 20px;
  display: block;
  color: #fff;
}

#hamburger::after,
#hamburger::before {
  content: "";
  display: block;
  width: 20px;
  height: 2px;
  background-color: currentColor;
  margin-top: 5px;
  transition: 0.3s;
  position: relative;
}

.active#hamburger {
  border-color: transparent;
}

.active#hamburger::before {
  transform: rotate(135deg);
}

.active#hamburger::after {
  transform: rotate(-135deg);
  top: -7px;
}

.ativo {
  color: #fff !important;
  font-weight: bolder;
}

.menu li:has(a):has(.ativo) {
  /* border-color: #fff; */
}

.menu li:not(:last-child) {
  padding: 5px;
}

.menu li a {
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s;
}

.menu li a:hover {
  color: white;
}

.btn-participe button {
  background-color: #fef176;
  font-weight: 800;
  padding: 10px 20px;
  border-radius: 20px;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
  color: #184091 !important;
  font-size: 1.2rem;
}

.btn-participe a,
.btn-participe a:hover {
  color: #fff !important;
}

.btn-participe:hover,
.menu li:hover {
  transform: scale(1.1);
}

.seta-participe {
  position: absolute;
  top: -15px;
  right: -20px;
}

@keyframes enterUp {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  100% {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
  }
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter {
  transform: translate3d(0, -20px, 0);
}

.v-leave-to {
  transform: translate3d(0, 20px, 0);
}

.v-enter-active,
.v-lave-active {
  transition: all 0.3s;
}

.btn-close {
  display: none !important;
}

@media (max-width: 1842px) {
  .showMenu {
    display: initial;
  }
  .menu {
    gap: 4px;
  }
}
@media (max-width: 1770px) {
  .menu li:not(:last-child) {
    border-top: solid 2px #54993c;
    border-bottom: solid 8px #54993c;
  }
  header::after {
    height: 16.4px;
    width: 100vw;
  }

  header {
    z-index: 195;
    overflow: hidden;
    height: 83px;
  }
  .ativo {
    color: white !important;
    font-weight: bolder;
  }

  .btn-default {
    font-size: 26px;
    font-weight: bold;
    padding: 8px 16px;
    color: #16326a;
  }

  .menu {
    display: none;
    flex-direction: column;
    position: fixed;
    width: 100%;
    background-color: #61af33;
    z-index: 199;
    right: 0;
    left: 0;
    top: 130px;
    bottom: 0;
    padding: 0;
  }

  #nav.active .menu {
    display: initial;
    animation: enterUp 0.3s;
  }

  .btn-close {
    display: block !important;
  }

  .menu li:hover {
    transform: scale(1);
  }

  #btn-mobile {
    display: flex;
    padding: 0.5rem 1rem;
    border: none;
    background: none;
    align-items: center;
  }

  .menu li {
    border-top: 0;
    max-width: 100%;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    border-top: 0 !important;
    font-size: 25px;
  }

  .btn-participe {
    width: 100%;
    border-radius: 0;
    margin-top: 10px;
  }

  .seta-participe {
    display: none;
  }

  .logo-header {
    width: 325px;
    z-index: 196;
  }

  .participe-mobile {
    background-color: #021378;
  }
  .btn-participar {
    background-color: transparent;
    color: #fff;
  }
}
@media (max-width: 970px) {
  @media (max-width: 768px) {
    .menu {
      top: 100px;
    }
    .area-1 {
      height: 48px;
      gap: 12px;
    }
    .area-1 a {
      font-size: 16px;
      color: #959595;
    }
    .btn-default {
      max-width: 273px;
    }
    header {
      height: 71px;
    }
    .logo-header {
      width: 268px;
    }
  }

  @media (max-width: 430px) {
    header {
      height: 71px;
    }
    .logo-header {
      width: 160px;
    }
  }
}
</style>
