<script setup>
import { ref, computed, onMounted } from "vue";
import { http } from "@/services/api";
import Section1 from "../../components/Section1.vue";
import Faq from "../../components/Faq.vue";

const form = ref({
  nome: "",
  email: "",
  telefone: "",
  mensagem: "",
});

const duvidas = ref([]);

const disabled = computed(() => {
  let disabled = true;

  if (!form.value.nome || !form.value.email || !form.value.mensagem) {
    disabled = true;
  } else disabled = false;

  return disabled;
});

async function sendContato() {
  const url = "contato";
  const res = await http.post(url, form.value);

  if (res.data.success) {
    window.__Swal.fire({
      title: "Sucesso",
      text: res.data.message,
      icon: "success",
    });
  }
}
async function getDuvidas() {
  const url = "duvidas";
  const res = await http.get(url);
  duvidas.value = res.data.duvidas;
}

onMounted(() => {
  getDuvidas();
  document.getElementById("nome").focus();
});
</script>

<template>
  <div class="container">
    <Section1 is-regulamento></Section1>
  </div>

  <div class="container my-5">
    <div v-if="duvidas.length > 0">

      <Faq :faq="duvidas"/>
  
    </div>

    <div class="row">
      <div class="col-md-4">
        <label>Nome <input id="nome" v-model="form.nome" type="text" /></label>
      </div>
      <div class="col-md-4">
        <label>Email <input v-model="form.email" type="email" /></label>
      </div>
      <div class="col-md-4">
        <label
          >Telefone
          <input
            v-model="form.telefone"
            v-maska="'(##) #####-####'"
            type="text"
        /></label>
      </div>
      <div class="col-12">
        <label
          >Mensagem
          <textarea
            v-model="form.mensagem"
            class="form-control"
            cols="30"
            rows="7"
          ></textarea>
        </label>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button
        :disabled="disabled"
        type="submit"
        class="btn-default mt-4"
        :class="{ disabled: disabled }"
        @click.prevent="sendContato"
      >
        Enviar
      </button>
    </div>
  </div>
</template>

<style scoped>
h1 {
  font-size: 30px;
  color: #fff;
  text-align: center;
}
h2 {
  font-size: 30px;
  font-weight: bolder;
  color: white;
  text-transform: uppercase;
  text-align: center;
}
h2 span {
  color: #53b95d;
  cursor: pointer;
}
h2 span:hover {
  text-decoration: underline;
}

.btn-participe {
  background-color: #4fb75a;
  color: white;
  font-weight: bolder;
  font-size: 40px;
  padding: 8px 45px;
  z-index: 6;
}

.img-arc {
  position: absolute;
  top: 50%;
  z-index: 5;
}
.area-btn-participe {
  z-index: 6;
}
.row-passos {
  z-index: 6;
}

.card {
  border-radius: 0;
}

textarea {
  font-size: 20px !important;
  font-weight: 600 !important;
}

@media (max-width: 768px) {
  .row-passos {
    gap: 20px;
  }
}
</style>
